<template>
  <AppBanner ref="banner" :url="require('@/assets/image/cover/csr.png')" title="ESG企業永續" />
  <!-- nav -->
  <AppContentNav ref="nav" :items="navItems" />

  <article>
    <!-- 公司政策 -->
    <section class="py-6 py-lg-8" ref="policy">
      <div class="container">
        <div class="px-lg-8">
          <h2 class="content-title fs-1 text-center mb-8">公司政策</h2>
          <div class="mb-3 mb-lg-5">
            <p class="fw-light mb-3 lh-base">
              仁大資訊為知名系統整合服務公司，我們視企業永續發展與社會參與為公司成長的機會。積極實踐企業社會責任，以符合國際發展趨勢，並透過企業公民相關議題推動，提升國家經濟貢獻，直接或間接方式改善員工、環境、社會生活品質，促進以企業社會責任為本競爭優勢。
            </p>
            <p class="fw-light">仁大資訊承諾</p>
            <ul class="fw-light lh-base">
              <li>
                一、透過健全的制度進行公司治理，並持續實踐商業道德規範，恪遵政府相關法律規定。
              </li>
              <li>二、注重員工健康，並提供安全的工作環境，確保勞動力多元化及人權保障。</li>
              <li>
                三、對各種形式的賄賂、腐敗、敲詐勒索和貪污採取零容忍政策，秉承最高的誠信經營標準，引導公司管理人員及相關利益團體（如客戶、供應商等）依法辦事，誠實守信，樹立企業良好形象及正當的商業活動。
              </li>
              <li>
                四、透過積極參與慈善活動、企業實習教育計畫，將資源做最完善的分配，並發揮企業的社會影響力。
              </li>
              <li>
                五、強化並持續改善企業社會責任，將依企業社會責任衝擊與風險評估之年度報告，訂定相對應之管理方案及目標，以確保勞工、健康安全、環境及企業倫理之衝擊與風險獲得有效之管理
              </li>
            </ul>
          </div>
          <div class="px-8 mb-3 mb-lg-5">
            <div class="d-flex">
              <div class="mx-4">
                <img src="@/assets/image/csr/csr-policy-1.png" alt="csr-policy-1.png" />
              </div>
              <div class="mx-4">
                <img src="@/assets/image/csr/csr-policy-2.png" alt="csr-policy-2.png" />
              </div>
              <div class="mx-4">
                <img src="@/assets/image/csr/csr-policy-3.png" alt="csr-policy-3.png" />
              </div>
              <div class="mx-4">
                <img src="@/assets/image/csr/csr-policy-4.png" alt="csr-policy-4.png" />
              </div>
              <div class="mx-4">
                <img src="@/assets/image/csr/csr-policy-5.png" alt="csr-policy-5.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 綠能環保 -->
    <section class="py-6 py-lg-8 bg-gray-100" ref="green">
      <div class="container">
        <div class="px-lg-8">
          <h2 class="content-title fs-1 text-center mb-8">綠能環保</h2>
          <p class="fw-light mb-3">
            仁大資訊逾半的業務收入來自於銷售國內外科技大廠的軟硬體，其次為資訊顧問諮詢，再加上少量的租賃服務，因此在仁大全省14個辦公據點所造成的直接環境衝擊，相對有限。
          </p>

          <ul class="fw-light mb-3 mb-lg-5">
            <li>一、辦公樓層集中化</li>
            <li>二、包裝工業化</li>
            <li>三、工單無紙化</li>
            <li>四、淨灘護海岸，為大自然盡一份心力。</li>
          </ul>

          <div class="row">
            <div class="col-md-6">
              <img
                src="@/assets/image/green/green-01.png"
                class="img-fluid"
                alt="包裝工業化"
                height="100"
              />
            </div>
            <div class="col-md-6">
              <img src="@/assets/image/green/green-02.png" class="img-fluid" alt="包裝工業化" />
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-6">
              <img
                src="@/assets/image/green/green-03.png"
                class="img-fluid"
                alt="淨灘護海岸，為大自然盡一份心力"
                height="100"
              />
            </div>
            <div class="col-md-6">
              <img
                src="@/assets/image/green/green-04.png"
                class="img-fluid"
                alt="淨灘護海岸，為大自然盡一份心力"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 社會參與 -->
    <section class="py-6 py-lg-8" ref="social">
      <div class="container">
        <div class="px-lg-8">
          <h2 class="content-title fs-1 text-center mb-8">社會參與</h2>
          <p class="fw-light mb-3">
            仁大資訊身為企業公民的一份子，我們積極參與慈善活動、社會回饋與校企合作，並投入相關資源以創造社會價值，藉此提升公司的正面形象以及社會影響力，進而凝聚員工的向心力與相關利害關係人對仁大資訊的信任。
            因此社會參與是其中的一個重要面向，除了公益慈善之外，我們亦希望能結合公司核心能力投入數位包容(Digital
            Inclusion)，不僅協助學子或社區強化因應未來的數位社會，亦從中發掘潛力人才。
            除了透過公司福委會進行家電、衣物募集專案以外，我們也與遠傳及其他共36家供應商夥伴，為萬里國小修建電腦教室，優化偏鄉數位科技學習環境，讓偏鄉學童也能跟國際接軌，並且走訪老人院、為受扶助學子募集獎助學金，以及捐贈一台復康巴士供「聖方濟日照中心」使用。
          </p>

          <ul class="fw-light mb-3 mb-lg-5">
            <li>一、彰化家扶義賣</li>
            <li>二、遠傳電信-永續先鋒隊</li>
            <li>三、亞東醫院醫療物資捐贈</li>
            <li>四、彰化家扶捐贈機器人</li>
            <li>五、送愛不停歇，走訪老人院、捐贈復康巴士</li>
          </ul>

          <div class="row">
            <div class="col-md-6">
              <img
                src="@/assets/image/csr/social-01.png"
                class="img-fluid"
                alt="遠傳電信-永續先鋒隊"
                height="100"
              />
            </div>
            <div class="col-md-6">
              <img
                src="@/assets/image/csr/social-02.png"
                class="img-fluid"
                alt="亞東醫院醫療物資捐贈"
              />
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-6">
              <img
                src="@/assets/image/csr/social-03.png"
                class="img-fluid"
                alt="彰化家扶捐贈機器人"
                height="100"
              />
            </div>
            <div class="col-md-6">
              <img
                src="@/assets/image/csr/social-04.png"
                class="img-fluid"
                alt="送愛不停歇，走訪老人院、捐贈復康巴士"
              />
            </div>
          </div>

          <br />
        </div>
      </div>
    </section>

    <!-- 尊重勞權 -->
    <section class="py-6 py-lg-8 bg-gray-100" ref="labor">
      <div class="container">
        <div class="px-lg-8">
          <h2 class="content-title fs-1 text-center mb-8">尊重勞權</h2>
          <p class="fw-light mb-3">
            仁大資訊股份有限公司尊重並支持國際公認之人權規範與原則，包含「世界人權宣言」、「聯合國全球盟約」及國際勞工組織的「工作基本原則與權利宣言」，遵守相關政府法令規範，並依據「聯合國工商企業與人權指導原則」及「負責任商業聯盟行為準則（RBA）」制定人權政策。
          </p>

          <div class="fw-light mb-3 mb-lg-5">
            <p>人權承諾</p>
            <ul>
              <li>一、我們堅信尊重並保障人權是企業永續經營的重要根基。</li>
              <li>二、我們在營運的各項環節，均將人權議題列入考量。</li>
              <li>三、我們提供利害關係人安全及暢通的溝通管道。</li>
            </ul>
          </div>

          <div class="fw-light mb-3 mb-lg-5">
            <p>RBA 準則遵循</p>
            <p>
              尊重人權是企業永續經營的重要面向，我們對供應鏈也有相似的期望。為了尊重人權，仁大資訊在營運與供應鏈管理皆採用商業聯盟（Responsible
              Business Alliance,
              RBA）行為守則為管理框架，並於2021年12月取得負責任商業聯盟行為準則審核驗證。
            </p>
          </div>

          <div class="fw-light mb-3 mb-lg-5">
            <p class="fw-light">RBA政策申明</p>
            <ul class="fw-light ps-4">
              <li class="l-style-disc">
                遵守經營所在地的勞工、健康安全、和環保法律法規和相關國際標準。
              </li>
              <li class="l-style-disc">
                禁止使用童工、強迫勞動和囚工，不接受任何使用童工或強迫勞動的供應商或分包商。
              </li>
              <li class="l-style-disc">尊重員工自由，禁止任何形式的強迫勞動。</li>
              <li class="l-style-disc">
                保護環境，提供安全衛生的工作和生活條件,確保員工的安全和健康。
              </li>
              <li class="l-style-disc">
                推動勞資合作，尊重員工的結社自由、集體談判權和和平集會權。
              </li>
              <li class="l-style-disc">提供平等和公平的工作環境，禁止任何形式的歧視行為。</li>
              <li class="l-style-disc">合理安排生產計畫，合理安排員工的工作時間和休息時間。</li>
              <li class="l-style-disc">尊重員工的基本人權，禁止任何形式的侮辱人格的行為。</li>
              <li class="l-style-disc">提供合理的工資福利，至少滿足員工的基本需要。</li>
              <li class="l-style-disc">避免任何形式的貪污，敲詐勒索及挪用公款等行為。</li>
              <li class="l-style-disc">持續改善。</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- 道德誠信 -->
    <section class="py-6 py-lg-8" ref="morality">
      <div class="container">
        <div class="px-lg-8">
          <h2 class="content-title fs-1 text-center mb-8">道德誠信</h2>

          <p class="fw-light mb-3 mb-lg-5">
            仁大資訊為恪遵政府相關法令與謹守誠信道德規範之企業，視誠信正直與當責視為企業文化的一環，並透過完整的教育訓練與例行宣導，培養全體員工守法與強化道德誠信的觀念。
          </p>

          <div class="fw-light mb-3 mb-lg-5">
            <p class="">經營原則</p>

            <ul class="fw-light ps-4">
              <li class="l-style-disc">
                公司營運以誠實、誠信為基礎，所有員工應避免利益衝突、盜竊、敲詐勒索、貪污、保護企業資產、違反公平競爭、
                遵循法規、反腐敗或反賄賂、反壟斷、保護隱私、知識產權保護、負責任採購…等。
              </li>
              <li class="l-style-disc">
                同仁不因拒絕做任何不符合「最高誠信標準」政策的事情，以及拒絕表達/拒絕自願說出自己的決定而受到懲罰；同時對於員工之身份進行保護及禁止報復。
              </li>
              <li class="l-style-disc">禁止不合理禮品饋贈與款待。</li>
              <li class="l-style-disc">防止利益衝突。</li>
              <li class="l-style-disc">反貪污賄賂。</li>
              <li class="l-style-disc">財務誠信。</li>
              <li class="l-style-disc">監督與稽查。</li>
              <li class="l-style-disc">意見反饋與申訴。</li>
              <li class="l-style-disc">事件調查。</li>
            </ul>
          </div>

          <div class="fw-light mb-3 mb-lg-5">
            <p>履行誠信道德執行情形</p>
            <ul class="ps-4">
              <li class="l-style-disc">
                2021年本公司舉辦相關誠信經營內訓課程，參訓人員為451人次，合計共338.25小時。
              </li>
              <li class="l-style-disc">2021年本公司違反誠信申訴信箱及檢舉案件為0件。</li>
              <li class="l-style-disc">2021年本公司同仁並無發生違反誠信經營之行為。</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- 資訊安全政策 -->
    <section class="py-6 py-lg-8 bg-gray-100" ref="isp">
      <div class="container">
        <div class="px-lg-8">
          <h2 class="content-title fs-1 text-center mb-8">資訊安全政策</h2>
          <div class="fw-light mb-3 mb-lg-5">
            <ul class="fw-light ps-4">
              <li class="l-style-disc">強化人員認知、避免資料外洩。</li>
              <li class="l-style-disc">落實日常維運、確保服務可用。</li>
              <li class="l-style-disc">
                辦理資訊安全教育訓練，推廣員工資訊安全意識與強化其對相關責任之認知。
              </li>
              <li class="l-style-disc">
                保護本公司業務活動資訊，避免未經授權的存取與修改，確保其正確完整。
              </li>
              <li class="l-style-disc">每年定期進行稽核作業，確保相關規定皆能落實執行。</li>
              <li class="l-style-disc">確保本公司關鍵核心系統維持一定水準的系統可用性。</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- 品質保證 -->
    <section class="py-6 py-lg-8" ref="qa">
      <div class="container">
        <div class="px-lg-8">
          <h2 class="content-title fs-1 text-center mb-8">品質保證</h2>
          <p class="fw-light mb-3 mb-lg-5">
            本公司以「專業」、「承諾」、「效率」為經營理念，專注於提供客戶產品及服務品質，為讓理念落實，本公司以ISO9001之品質管理系統為基礎建立，並施行一套有效且能防患於未然的品質保證制度供全體員工遵循，並持續改善，藉此項措施來達成管理審查會議所訂之各項品質目標。
          </p>

          <div class="fw-light mb-3 mb-lg-5">
            <ul class="fw-light ps-4">
              <li class="l-style-disc">
                於2020年12月18日取得 ISO27001：2013 資訊安全管理系統認證。
              </li>
              <li class="l-style-disc">於2021年10月27日取得 ISO9001：2015 品質管理系統認證。</li>
              <li class="l-style-disc">於2023年12月18日取得 ISO27001：2022 品質管理系統認證。</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- 建議與申訴 -->
    <section class="py-6 py-lg-8 bg-gray-100" ref="advice">
      <div class="container">
        <div class="px-lg-8">
          <h2 class="content-title fs-1 text-center mb-8">建議與申訴</h2>
          <p class="fw-light">
            我們相當重視企業社會責任之相關如：商業道德誠信、勞權尊重、社會參與以及環境倡議…等負責任商業聯盟行為準則（Responsible
            Business Alliance,
            RBA）議題，如有相關議題上之檢舉投訴與回饋建議，可透過下述管道將您寶貴的意見與仁大資訊反饋。
          </p>
          <p class="fw-light mb-5">電子信箱：stanley_chen@hpicorp.com.tw</p>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  data() {
    return {
      navItems: [
        { id: "policy", name: "公司政策", path: "/csr?anchor=policy" },
        { id: "green", name: "綠能環保", path: "/csr?anchor=green" },
        { id: "social", name: "社會參與", path: "/csr?anchor=social" },
        { id: "labor", name: "尊重勞權", path: "/csr?anchor=labor" },
        { id: "morality", name: "道德誠信", path: "/csr?anchor=morality" },
        { id: "isp", name: "資訊安全政策", path: "/csr?anchor=isp" },
        { id: "qa", name: "品質保證", path: "/csr?anchor=qa" },
        { id: "advice", name: "建議與申訴", path: "/csr?anchor=advice" },
      ],
    };
  },
  computed: {
    anchor() {
      return this.$route.query.anchor;
    },
  },
  watch: {
    anchor(val) {
      this.scrollToRef(val);
    },
  },
  methods: {
    scrollToRef(refName) {
      let top;
      if (refName === "all") {
        top = 0;
      } else {
        let banner = this.$refs.banner.$refs.cover.offsetHeight;
        let nav = this.$refs.nav.$refs.navChild.offsetHeight;
        let element = this.$refs[refName];
        top = element.offsetTop + banner + nav;
      }
      setTimeout(() => {
        window.scrollTo(0, top);
      }, 0);
    },
  },
  mounted() {
    //這一定要跑兩次
    this.scrollToRef(this.anchor);
    window.onload = () => {
      this.scrollToRef(this.anchor);
    };
  },
};
</script>
